/* eslint-disable react/require-default-props */
import {
  useContext,
  ReactElement,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import styled from "styled-components";

import {
  Button,
  Icon,
  Heading,
  ProgressBar,
  ReadMore,
  Tabs,
  Breadcrumb,
} from "@harpercollins/harpercollins-design-system";
import { Form, NavigationContext, Link } from "@django-render/core";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import Scoring from "./Scoring";
import HeaderWrapper, {
  HeaderCommands,
  HeaderContent,
  HeaderTitle,
} from "../../../lib/components/HeaderWrapper";

import { DetailsWrapper, KPIs } from "./ManuscriptDetail.style";
import KPI from "../components/KPI";
import BaseLayout from "../../../lib/components/BaseLayout";
import { DetailedAnalysisResult, Manuscript, Timer } from "../../types";
import CharacterDescriptions from "./CharacterDescriptions";
import Metadata from "./Metadata";
import AI from "./AI";
import Plagiarism from "./Plagiarism";
import LongSummary from "./LongSummary";
import ShortSummary from "./ShortSummary";
import Bibliographic from "./Bibliographic/Bibliographic";
import ExpiryCountdown from "../components/ExpiryCountdown/ExpiryCountdown";
import { trafficLightRanges } from "../../../constants";
import ModalWindow from "../../../lib/components/ModalWindow";
import { CSRFTokenContext, URLsContext } from "../../../contexts";
import Competitors from "./Competitors";

const AnalysisProgressWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
  margin-top: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 20px;
`;

const BreadcrumbWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media print {
    display: none;
  }
`;

export interface ManuscriptDetailViewContext {
  manuscript: Manuscript<DetailedAnalysisResult>;
}

enum TabIds {
  SHORT_SUMMARY = "short-summary",
  LONG_SUMMARY = "long-summary",
  SCORING = "scoring",
  COMPETITORS = "competitors",
  CHARACTER_DESCRIPTIONS = "character-descriptions",
  METADATA = "metadata",
  BIBLIOGRAPHIC = "bibliographic",
  AI_FREE = "ai-free",
  PLAGIARISM_FREE = "plagiarism-free",
}

function ManuscriptDetailView({
  manuscript,
}: ManuscriptDetailViewContext): ReactElement {
  const { openOverlay, refreshProps } = useContext(NavigationContext);
  const urls = useContext(URLsContext);
  const csrfToken = useContext(CSRFTokenContext);

  const renderModalWindow = useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  // Refresh once per two seconds while in progress
  useEffect(() => {
    // if the analysis is not pending or running, don't refresh
    if (
      manuscript.last_analysis?.status.code !== "pending" &&
      manuscript.last_analysis?.status.code !== "running"
    ) {
      return () => {};
    }

    let timeout: Timer | null = null;

    const scheduleRefreshContext = () => {
      // eslint-disable-next-line no-void
      timeout = setTimeout(() => void refreshProps(), 2000);
    };

    scheduleRefreshContext();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [manuscript.last_analysis?.status, refreshProps]);

  const analysis = manuscript.last_analysis;
  let analysisRendered = null;

  if (analysis?.result) {
    const tabs = [
      {
        label: "Short Summary",
        id: TabIds.SHORT_SUMMARY,
        content: <ShortSummary shortSummary={analysis.result?.short_summary} />,
      },
      {
        label: "Long Summary",
        id: TabIds.LONG_SUMMARY,
        content: <LongSummary longSummary={analysis.result?.long_summary} />,
      },
      {
        label: "Scoring",
        id: TabIds.SCORING,
        content: <Scoring scoring={analysis.result.scoring} />,
      },
      {
        label: "Competitors",
        id: TabIds.COMPETITORS,
        content: (
          <Competitors
            competitors={analysis.competitors}
            add_competitor_url={analysis.add_competitor_url}
          />
        ),
      },
      {
        label: "Character Descriptions",
        id: TabIds.CHARACTER_DESCRIPTIONS,
        content: (
          <CharacterDescriptions
            characters={analysis.result?.characters?.characters}
          />
        ),
      },
      {
        label: "Metadata",
        id: TabIds.METADATA,
        content: <Metadata metadata={analysis.result.metadata} />,
      },
      {
        label: "Bibliographic",
        id: TabIds.BIBLIOGRAPHIC,
        content: <Bibliographic manuscript={manuscript} />,
      },
      {
        label: "AI Free",
        id: TabIds.AI_FREE,
        content: (
          <AI
            ai={analysis.result?.originality?.ai}
            ai_free={analysis.result?.ai_free}
          />
        ),
      },
      {
        label: "Plagiarism Free",
        id: TabIds.PLAGIARISM_FREE,
        content: (
          <Plagiarism plagiarism={analysis.result?.originality?.plagiarism} />
        ),
      },
    ];

    analysisRendered = (
      <>
        <HeaderTitle $centered>
          <Heading as="h1">{manuscript.title}</Heading>
          {manuscript.author.canonical_name && (
            <p>By {manuscript.author.canonical_name}</p>
          )}
        </HeaderTitle>
        <HeaderContent $centered>
          <ReadMore maxLines={3}>
            <ShortSummary shortSummary={analysis.result.micro_summary} />
          </ReadMore>
          <DetailsWrapper>
            <KPIs>
              <KPI
                name="Overall score"
                percentage={analysis?.result?.scoring?.overall_score}
                tooltip="The score is an average between the different KPIs that can be found in the tab “Score”. Each KPI is calculated by comparing the Manuscript's content against the best reference within that genre in the LLM. The higher the score, the more quality the Manuscript has."
                trafficLightRanges={trafficLightRanges.score}
              />
              <KPI
                name="AI free"
                percentage={analysis?.result?.ai_free}
                tooltip={`This score reflects our AI's confidence in predicting that the content scanned was produced by a human being. The detector is able to identify several AI models, including GPT4. A score of 90% original and 10% AI should be thought of as "We are 90% confident that this content was created by a human" and NOT that 90% of the article is Human and 10% AI.`}
                trafficLightRanges={trafficLightRanges.aiFree}
              />
              <KPI
                name="Plagiarism free"
                percentage={analysis?.result?.plagiarism_free}
                tooltip={`This is the percent of your content that is not plagiarised. The higher the score the better. A very low number means that there are too many parts of the content that have been plagiarised. Refer to the tab "Plagiarism" below to view the specific websites we found the plagiarism on.`}
                trafficLightRanges={trafficLightRanges.plagiarismFree}
              />
              <KPI
                name="Readability score"
                percentage={analysis?.result?.readability}
                tooltip="Readability refers to how easy or difficult it is to read a piece of writing. The score is influenced by factors like sentence structure, word choice, and overall organization of the text. A 100% means that's very easy to read and understand by an average 11-year-old student whilst a 10% means that it's a professional text, best understood by university graduates and extremely difficult to read."
                trafficLightRanges={trafficLightRanges.readability}
              />
            </KPIs>
            <Tabs tabsConfig={tabs} />
          </DetailsWrapper>
        </HeaderContent>
      </>
    );
  }

  return (
    <BaseLayout>
      <HeaderWrapper>
        <BreadcrumbWrapper>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link href={urls.manuscript_list}>← Manuscripts</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{manuscript.title}</Breadcrumb.Item>
          </Breadcrumb>

          <HeaderCommands>
            {manuscript.last_analysis &&
              manuscript.last_analysis.status.code === "succeeded" && (
                <>
                  <ExpiryCountdown expiryDate={manuscript.expires_at} />

                  <Button
                    type="button"
                    kind="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      window.print();
                    }}
                  >
                    <Icon icon={faFileArrowDown} />
                    Export Analysis
                  </Button>
                </>
              )}
            <Button
              type="button"
              kind="secondary"
              onClick={() =>
                openOverlay(manuscript.edit_url, renderModalWindow, {
                  onClose: () => {
                    // Refresh the context when the modal is closed so the changes take effect
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    refreshProps();
                  },
                })
              }
            >
              Edit
            </Button>
            <Button
              type="button"
              kind="danger"
              onClick={() =>
                openOverlay(
                  `${manuscript.delete_url}?next=${urls.manuscript_list}`,
                  renderModalWindow,
                  {
                    onClose: () => {
                      // Refresh the context when the modal is closed so the changes take effect
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      refreshProps();
                    },
                  },
                )
              }
            >
              Delete
            </Button>
          </HeaderCommands>
        </BreadcrumbWrapper>
        {analysisRendered}

        <AnalysisProgressWrapper>
          {manuscript.last_analysis &&
            manuscript.last_analysis.status.code === "pending" && (
              <Heading as="h4">Analysis is queued</Heading>
            )}

          {manuscript.last_analysis &&
            manuscript.last_analysis.status.code === "running" && (
              <>
                {manuscript.last_analysis.progress === null && (
                  <Heading as="h4">Analysis is starting</Heading>
                )}
                {manuscript.last_analysis.progress !== null && (
                  <>
                    <Heading as="h4">Analysis is in progress</Heading>
                    <ProgressBar
                      ariaLabel="Analysis progress"
                      percentage={manuscript.last_analysis.progress}
                    />
                  </>
                )}
              </>
            )}

          {analysis &&
            analysis.status.code === "failed" &&
            manuscript.retry_analysis_url && (
              <>
                <Heading as="h4">Analysis failed</Heading>
                <p>
                  {analysis.status?.failure_message
                    ? analysis.status?.failure_message
                    : `The analysis has failed. Please retry the analysis once and
                    contact the ScriptScan team if the problem persists.`}
                </p>
                <Form action={manuscript.retry_analysis_url} method="post">
                  <input
                    type="hidden"
                    name="csrfmiddlewaretoken"
                    value={csrfToken}
                  />
                  {analysis.status?.can_retry ? (
                    <Button type="submit" kind="primary">
                      Retry Analysis
                    </Button>
                  ) : null}
                </Form>
              </>
            )}
        </AnalysisProgressWrapper>
      </HeaderWrapper>
    </BaseLayout>
  );
}

export default ManuscriptDetailView;
